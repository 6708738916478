import React from "react";
import { Helmet } from "react-helmet";

const Plausible = () => {
	// Extract hostname from window location
	const domain = window.location.hostname;

	return (
		<>
			{process.env.NODE_ENV === "production" && (
				<Helmet>
					<script
						defer
						data-domain={domain}
						src="https://plausible.io/js/script.js"
					></script>
				</Helmet>
			)}
		</>
	);
};

export default Plausible;
