import { RouterProvider } from "react-router-dom";
import TanstackQueryProvider from "./providers/tanstack-query-provider";
import { router } from "./routes";
import MetaPixel from "./components/MetaPixel";
// import LogRocket from "logrocket";
import Plausible from "./components/Plausible";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";
import { Toaster } from "sonner";
import { GTM_IDS } from "./lib/config";
import GoogleTagManager from "./components/GoogleTagManager";
import isDev from "./lib/DevDetect";

// if (process.env.NODE_ENV === "production")
// 	LogRocket.init("mjknmk/daily-sale-shop");

function App() {
	const isProduction = !isDev();
	// const isProduction = process.env.NODE_ENV === "production";

	const domain = window.location.hostname as keyof typeof GTM_IDS;

	return (
		<CacheBuster
			currentVersion={version}
			isEnabled={isProduction} //If false, the library is disabled.
			isVerboseMode={false} //If true, the library writes verbose logs to console.
			loadingComponent={null} //If not pass, nothing appears at the time of new version check.
			metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
		>
			<TanstackQueryProvider>
				{isProduction && <GoogleTagManager gtmId={GTM_IDS[domain]} />}
				<MetaPixel />
				<Plausible />
				{/* here we add more providers, like global modals, auth etc */}
				<RouterProvider router={router} />
				<Toaster
					position="bottom-right"
					expand={true}
					richColors
					closeButton
				/>
			</TanstackQueryProvider>
		</CacheBuster>
	);
}

export default App;
