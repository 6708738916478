"use client";
import { useEffect } from "react";
import axios, { AxiosError } from "axios";
import type { AxiosInstance } from "axios";
import useAuth from "./useAuth";
import { DSS_URL } from "@/lib/config";
import { getCorridor } from "@/lib/utils";

export const axiosInstance: AxiosInstance = axios.create({
	baseURL: "/" /** Set the correct baseURL from env file here*/,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		DSS_CORRIDOR: getCorridor().code.toLocaleUpperCase(),
	},
});

export const axiosInstanceNoAuth: AxiosInstance = axios.create({
	baseURL: `${DSS_URL}/` /** Set the correct baseURL from config here*/,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		DSS_CORRIDOR: getCorridor().code.toLocaleUpperCase(),
	},
});

const useAxiosAuth = () => {
	const authState = useAuth();

	useEffect(() => {
		const requestInterceptor = axiosInstance.interceptors.request.use(
			(config) => {
				if (authState.accessToken) {
					config.headers["Authorization"] =
						"Bearer " + authState.accessToken;
				}
				return config;
			},
			(error) => Promise.reject(error)
		);

		const responseInterceptor = axiosInstance.interceptors.response.use(
			(response) => {
				return response;
			},
			async (error: AxiosError) => {
				const previousRequest = error.config;
				if (
					error.response?.status === 401 ||
					error.response?.status === 403
				) {
					// handle refresh here
					const refreshToken = authState.refreshToken;
					// ...
				}
			}
		);

		return () => {
			axiosInstance.interceptors.request.eject(requestInterceptor);
			axiosInstance.interceptors.response.eject(responseInterceptor);
		};
	}, [authState]);

	return {
		axiosWithAuth: axiosInstance,
		axiosNoAuth: axiosInstanceNoAuth,
	};
};

export default useAxiosAuth;
