import { CartItem } from "@/hooks/useShoppingCart";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function formatNumber(num: number, round: boolean = true): string {
	let formattedNum: string;

	if (round) {
		// Round the number to two decimal places if round is true
		formattedNum = (Math.round(num * 100) / 100).toFixed(2); // Ensures two decimal places
	} else {
		// If not rounding, convert directly to string without altering decimals
		formattedNum = num.toString();
		const parts = formattedNum.split(".");
		parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add thousands separator
		formattedNum = parts.join("."); // Rejoin integer and decimal parts
		return formattedNum; // Return early for non-rounded numbers
	}

	// For rounded numbers, process further for thousands separator
	const parts = formattedNum.split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join("."); // Return the formatted number
}

export function replaceMentions(input: string): string {
	// Regular expression to match 'takealot', 'take a lot' with optional '.com' or 'dot com'
	// The expression is case insensitive (/i), and looks for all matches (/g)
	const pattern = /take\s*a\s*lot(\.com| dot com)?/gi;

	// Replace all occurrences with 'DailySaleShop'
	return input.replace(pattern, "DailySaleShop");
}

export function getCurrentHost(): string {
	return window.location.hostname;
}

export function getCurrency(): string {
	return getCurrentHost() === "dailysale.co.zm" ? "ZMW" : "USD";
}

type Social = {
	platform: "facebook" | "instagram";
	url: string;
};

export function getCorridor(): {
	code: string;
	currency: string;
	country: string;
	centralCity: string;
	whatsapp: string;
	secondaryPhone?: string;
	email: string;
	partner: string;
	minimumOrder: number;
	depositAmount: number;
	depositPercentage: number;
	social: Social[];
} {
	return getCurrentHost() === "dailysale.co.zm"
		? {
				code: "zm",
				currency: "ZMW",
				country: "Zambia",
				centralCity: "Lusaka",
				whatsapp: "+27634853004",
				secondaryPhone: "+260974089578",
				email: "info@thedailysale.shop",
				partner: "Mercury Logistics",
				minimumOrder: 1300,
				depositAmount: 2_600_000,
				depositPercentage: 30,
				social: [
					{
						platform: "facebook",
						url: "https://www.facebook.com/dailysalezambia",
					},
				],
			}
		: {
				code: "zw",
				currency: "USD",
				country: "Zimbabwe",
				centralCity: "Harare",
				whatsapp: "+263789951002",
				email: "info@thedailysale.shop",
				partner: "FedEx",
				minimumOrder: 50,
				depositAmount: 1500,
				depositPercentage: 30,
				social: [
					{
						platform: "facebook",
						url: "https://www.facebook.com/dailysaleshopzw",
					},
					{
						platform: "instagram",
						url: "https://www.instagram.com/the_daily_sale_shop_zim",
					},
				],
			};
}

export function calculatePercentage(
	percent: number,
	num: number
): number | null {
	if (!Number.isFinite(percent) || !Number.isFinite(num)) {
		throw new Error("Both inputs must be valid numbers");
	}

	return (percent / 100) * num;
}

export function getDeliveryDate(
	workingDaysToAdd: number,
	total: boolean = false
): string {
	const weekDays = total
		? [
				"Sunday",
				"Monday",
				"Tuesday",
				"Wednesday",
				"Thursday",
				"Friday",
				"Saturday",
			]
		: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	// Get current date and time in CAT (UTC+2)
	const currentDate = new Date();
	currentDate.setHours(
		currentDate.getHours() + 2 - currentDate.getTimezoneOffset() / 60
	);

	// If current time is after 2pm, start counting from tomorrow
	if (currentDate.getHours() >= 14) {
		currentDate.setDate(currentDate.getDate() + 1);
	}

	// Adjust for weekends (if starting on a weekend, move to next Monday)
	if (currentDate.getDay() === 0) {
		// Sunday
		currentDate.setDate(currentDate.getDate() + 1); // Move to Monday
	} else if (currentDate.getDay() === 6) {
		// Saturday
		currentDate.setDate(currentDate.getDate() + 2); // Move to Monday
	}

	// Count working days
	while (workingDaysToAdd > 0) {
		currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
		if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
			// Weekday check
			workingDaysToAdd--; // Decrement only on weekdays
		}
	}

	// Formatting the date
	const dayOfWeek = weekDays[currentDate.getDay()];
	const dayOfMonth = currentDate.getDate();
	const month = months[currentDate.getMonth()];

	// Return the formatted date string
	return total
		? `${dayOfWeek} ${dayOfMonth} ${month}`
		: `Order Now, Receive ${dayOfWeek} ${dayOfMonth} ${month}`;
}

function getTomorrowsDate(): string {
	const tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);

	const options: Intl.DateTimeFormatOptions = {
		weekday: "short",
		day: "numeric",
		month: "short",
	};

	// Get the date string and remove any commas
	return tomorrow.toLocaleDateString("en-US", options).replace(/,/g, "");
}

export function calculateFinalDeliveryDate(orders) {
	let farthestDate = null;
	let hasTomorrow = false;
	let hasOtherDates = false;

	for (const order of orders) {
		const dateStr = order.next_delivery_date;

		if (dateStr === "Tomorrow") {
			hasTomorrow = true;
		} else {
			hasOtherDates = true;
			const orderDate = new Date(
				dateStr + " " + new Date().getFullYear()
			);
			if (!farthestDate || orderDate > farthestDate) {
				farthestDate = orderDate;
			}
		}
	}

	const result = { final_date: null, actual_final_date: null, message: null };

	if (hasTomorrow && hasOtherDates) {
		result.message = "Your cart contains items that may delay your order.";
		result.final_date = farthestDate
			? farthestDate.toDateString().slice(0, -5)
			: null; // Remove year
	} else if (hasTomorrow) {
		result.final_date = "Tomorrow";
		result.actual_final_date = getTomorrowsDate();
	} else if (farthestDate) {
		result.final_date = farthestDate.toDateString().slice(0, -5); // Remove year
	}

	return result;
}

export function calculateOrderSummary(
	deliveryFeeConfig,
	subtotal,
	isLocal,
	isOld = false
) {
	const zone = isLocal ? "LOCAL" : "OUT_OF_TOWN";

	let deliveryFee = 0;
	let cashCollectionFee = 0;
	let total = 0;

	const prematureReturn = {
		subtotal,
		deliveryFee: Number(0).toFixed(2),
		isLocal,
		total: Number(0).toFixed(2),
		cashCollectionFee: Number(0).toFixed(2),
	};

	if (!deliveryFeeConfig) {
		return prematureReturn;
	}

	const zoneConfig = deliveryFeeConfig[zone];

	// console.log("zoneConfig", zoneConfig);
	if (!zoneConfig) {
		return prematureReturn;
	}

	if (isOld) {
		deliveryFee = subtotal * 0.07; // 7% of invoice value
	} else if (zoneConfig.tiers) {
		for (const tier of zoneConfig.tiers) {
			if (
				subtotal >= tier.min_amount &&
				(subtotal <= tier.max_amount || tier.max_amount === null)
			) {
				switch (tier.fee_type) {
					case "fixed":
						deliveryFee = tier.fee_value;
						break;
					case "percentage":
						deliveryFee = subtotal * tier.fee_value;
						break;
					case "cash_collection":
						cashCollectionFee = tier.fee_value;
						break;
				}
			}

			// if (
			// 	subtotal >= tier.min_amount &&
			// 	(subtotal <= tier.max_amount || tier.max_amount === null)
			// ) {
			// 	deliveryFee =
			// 		tier.fee_type === "fixed"
			// 			? tier.fee_value
			// 			: tier.fee_type === "percentage"
			// 				? subtotal * tier.fee_value
			// 				: 0;
			// 	cashCollectionFee =
			// 		tier.fee_type === "cash_collection" ? tier.fee_value : 0;
			// 	// break;
			// }
		}

		// console.log("cashCollectionFee", cashCollectionFee);
	} else {
		// OUT_OF_TOWN logic
		deliveryFee = subtotal * zoneConfig.percentage_fee; // Base delivery fee is percentage
		cashCollectionFee = zoneConfig.fixed_fee; // Cash collection fee remains the same
		total += cashCollectionFee;
	}

	total += cashCollectionFee;

	// Rounding logic from the original calculateDeliveryFee function
	total += subtotal + deliveryFee;
	const roundedTotal = Math.ceil(total);
	if (roundedTotal !== total) {
		deliveryFee += roundedTotal - total; // Adjust deliveryFee to make total a whole number
		total = roundedTotal;
	}

	return {
		subtotal,
		deliveryFee: Number(deliveryFee).toFixed(2),
		isLocal,
		total: Number(total).toFixed(2),
		cashCollectionFee: Number(cashCollectionFee).toFixed(2),
	};
}

export function getMaxDeliveryDays(cartItems: CartItem[]): number {
	let maxDeliveryDays = 0;
	cartItems.forEach((item) => {
		if (item.delivery_days > maxDeliveryDays) {
			maxDeliveryDays = item.delivery_days;
		}
	});
	return maxDeliveryDays;
}

export function calculateDeliveryFeeOld(
	amount: number,
	isCOD: boolean
): number {
	console.log("amount", amount);
	let deliveryFee = 0;

	// Determine the delivery fee based on the amount
	// if (amount >= 0 && amount <= 50) {
	// 	deliveryFee = 5;
	// } else if (amount > 50 && amount <= 200) {
	// 	deliveryFee = 10;
	// } else if (amount > 200) {
	// 	deliveryFee = amount * 0.05; // 5% of invoice value
	// }

	// Just add 7% for every delivery
	deliveryFee = amount * 0.07; // 5% of invoice value

	// Add a Cash Collection Fee if the payment option is Cash On Delivery
	if (isCOD) {
		deliveryFee += 5;
	}

	// Add Cash Collection Fee
	// deliveryFee += 3;

	return Math.ceil(Number(deliveryFee));
}

export function calculateDeliveryFee(amount: number, isCOD: boolean): number {
	let deliveryFee = amount * 0.07; // 7% of invoice value

	// Add a Cash Collection Fee if the payment option is Cash On Delivery
	if (isCOD) {
		deliveryFee += 5;
	}

	// Calculate total and adjust the delivery fee if necessary
	const total = amount + deliveryFee;
	const roundedTotal = Math.ceil(total);

	// Check if the total needs to be adjusted to the nearest whole number
	if (roundedTotal !== total) {
		// Adjust the delivery fee so the total is a whole number
		deliveryFee += roundedTotal - total;
	}

	// return Math.ceil(deliveryFee);
	return deliveryFee;
}

export function checkCartStatus(cartItems: CartItem[]): {
	priceChanged: boolean;
	outOfStock: boolean;
} {
	let priceChanged = false;
	let outOfStock = false;

	for (const product of cartItems) {
		if (!priceChanged && product.old_price !== null) {
			priceChanged = true; // A price change is detected if any product's old_price is not null.
		}
		if (!outOfStock && product.delivery_days === 0) {
			outOfStock = true; // Product is considered out of stock if delivery_days is 0.
		}

		// If both conditions are met, no need to continue checking the rest
		if (priceChanged && outOfStock) {
			break;
		}
	}

	return { priceChanged, outOfStock };
}
